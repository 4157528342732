import React, { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import styles from "./Breadcrumb.module.css";
import { Link, useNavigate } from "react-router-dom";
import "./Breadcrumb.module.css";
import './Breadcrumb.css'
function BreadcrumbComponent({ breadcrumbItems }) {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <nav className="breadcumb-custom" style={{ '--bs-breadcrumb-divider': "url('/images/arrow-breadcumb-right.svg')" }} aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbItems.map((item, index) => {
          return (
            <li
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(item.href)}
              className={
                "breadcrumb-item" +
                (index === breadcrumbItems.length - 1 ? " active" : "")
              }
              aria-current="page"
            >
              {item.text}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default BreadcrumbComponent;
