import styles from "./Requirements.module.css"; // Import your CSS styles
import parse from "html-react-parser";
function Requirements({ data }) {
  return (
    <div className={styles.root_container}>
      <div className={styles.title}>Yêu cầu</div>
      <div className={styles.divide} />

      <div className="container-fluid p-0">
        {/* <div className="row">
          {data.map((item, index) => {
            return (
              <div key={index} className="col-xs-12 col-md-6 mt-2">
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <img
                    width={24}
                    height={24}
                    src={
                      require("assets/images/icon/svg/light/uis_check.svg")
                        .default
                    }
                  />
                  <span style={{ lineHeight: "30px" }}>{item}</span>
                </div>
              </div>
            );
          })}
        </div> */}
        {parse(data)}
      </div>
    </div>
  );
}

export default Requirements;
