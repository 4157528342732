import TeacherAvatar from "assets/images/pictures/png/course-teacher-avatar-demo.png";
import MinorCourseCardImage from "assets/images/pictures/png/course-thumbnail.png";
import { MinorCourseCard } from "./MinorCourseCard/MinorCourseCard";
import styles from "./CategoryCourse.module.css";
import { TagTitle } from "components/ui/TagTitle/TagTitle";
import RegisterSession from "components/RegisterSession/RegisterSession";
import apiService from "services/api.ts";
import { useEffect, useState } from "react";
import Loading from "components/ui/Loading/Loading";
import { useParams, useNavigate } from "react-router-dom";

const ApiService = new apiService();
export default function CategoryCourse() {
  const { programId } = useParams();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [courseData, setCourseData] = useState(null);

  async function getProgramDetail() {
    setIsFetching(true);
    try {
      const { data } = await ApiService.executeGetPathRequest(
        "education/program",
        { id: programId }
      );
      setCourseData(data.data);
    } catch (error) {}
    setIsFetching(false);
  }

  useEffect(() => {
    getProgramDetail();
  }, [programId]);
  const fakeData = {
    title: "Quản trị rủi ro tài chính",
    desc: "Chương trình này nhằm đào tạo người học xây dựng khả năng quản trị rủi ro giúp tối ưu hóa kế hoạch tài chính của mình.",

    // khoa_hoc_chuan_bi: {
    //   title: "Khoá học chuẩn bi",
    //   data: [
    //     {
    //       title: "Nguyên lý và tầm quan trọng của tài chính cá nhân",
    //       img_url: MinorCourseCardImage,
    //       time: 99,
    //       teacher: {
    //         avatar: TeacherAvatar,
    //         name: "Lộc Nguyễn",
    //       },
    //     },
    //     {
    //       title: "Nguyên lý và tầm quan trọng của tài chính cá nhân",
    //       img_url: MinorCourseCardImage,
    //       time: 99,
    //       teacher: {
    //         avatar: TeacherAvatar,
    //         name: "Lộc Nguyễn",
    //       },
    //     },
    //     {
    //       title: "Nguyên lý và tầm quan trọng của tài chính cá nhân",
    //       img_url: MinorCourseCardImage,
    //       time: 99,
    //       teacher: {
    //         avatar: TeacherAvatar,
    //         name: "Lộc Nguyễn",
    //       },
    //     },
    //   ],
    // },
    // khoa_hoc_cot_loi: {
    //   title: "Khoá học cốt lõi",
    //   data: [
    //     {
    //       title: "Nguyên lý và tầm quan trọng của tài chính cá nhân",
    //       img_url: MinorCourseCardImage,
    //       time: 99,
    //       teacher: {
    //         avatar: TeacherAvatar,
    //         name: "Lộc Nguyễn",
    //       },
    //     },
    //     {
    //       title: "Nguyên lý và tầm quan trọng của tài chính cá nhân",
    //       img_url: MinorCourseCardImage,
    //       time: 99,
    //       teacher: {
    //         avatar: TeacherAvatar,
    //         name: "Lộc Nguyễn",
    //       },
    //     },
    //     {
    //       title: "Nguyên lý và tầm quan trọng của tài chính cá nhân",
    //       img_url: MinorCourseCardImage,
    //       time: 99,
    //       teacher: {
    //         avatar: TeacherAvatar,
    //         name: "Lộc Nguyễn",
    //       },
    //     },
    //   ],
    // },

    // khoa_hoc_tu_chon: {
    //   title: "Khoá học tự chọn",
    //   data: [
    //     {
    //       title: "Nguyên lý và tầm quan trọng của tài chính cá nhân",
    //       img_url: MinorCourseCardImage,
    //       time: 99,
    //       teacher: {
    //         avatar: TeacherAvatar,
    //         name: "Lộc Nguyễn",
    //       },
    //     },
    //     {
    //       title: "Nguyên lý và tầm quan trọng của tài chính cá nhân",
    //       img_url: MinorCourseCardImage,
    //       time: 99,
    //       teacher: {
    //         avatar: TeacherAvatar,
    //         name: "Lộc Nguyễn",
    //       },
    //     },
    //     {
    //       title: "Nguyên lý và tầm quan trọng của tài chính cá nhân",
    //       img_url: MinorCourseCardImage,
    //       time: 99,
    //       teacher: {
    //         avatar: TeacherAvatar,
    //         name: "Lộc Nguyễn",
    //       },
    //     },
    //   ],
    // },

    kiem_tra_khoa_hoc: {
      title: "Kiểm tra khoá học",
      data: [
        "Trong mỗi khóa học sẽ có 1 vài bài kiểm tra được sắp xếp trong chương trình học",
        "Bài kiểm tra dạng quiz, cho phép attempt nhiều lần. Số lần được attempt  được giới hạn (3 lần ngày)",
        "Người học phải hoàn thành các bài kiểm tra này trước khi xem được các phần học tiếp theo",
        "Sau khi hoàn thành khóa học, người học sẽ nhận được 1 certificate với tên của mình trên certificate.",
      ],
    },
  };

  if (isFetching) {
    return <Loading style={{ marginTop: "300px", marginBottom: "300px" }} />;
  }
  if (!courseData) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.heading_container}>
        <div className="container">
          <div className="row gy-3">
            <div className="col-xs-12 col-md-6">
              <div className="d-grid gap-3">
                <div className={styles.title_heading}>{courseData.name}</div>
                <div className={styles.text_heading}>
                  {courseData.description}
                </div>
                <div>
                  <button
                    className="button-gradient"
                    style={{ width: "162px" }}
                    onClick={() => {navigate('/register')}}
                  >
                    Đăng ký
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div>
                <video
                  controls
                  className={styles.video}
                  src={courseData.video_url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"container"}>
        <div className={styles.session_container}>
          <TagTitle text={"Tổng quan chương trình"} />

          {courseData.course.map((item, index) => {
            console.log(item);
            return (
              <div className={styles.subsession}>
                <div className={styles.title}>{item.name}</div>
                <div className="container-fluid p-0">
                  <div className="row gy-3">
                    {item.course.map((_item, index) => {
                      return (
                        <div key={index} className="col-xs-12 col-md-6">
                          <MinorCourseCard data={_item} courseId={_item.id} />{" "}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <div className={styles.session_container}>
          
          <div
            className={styles.subsession + ' gap-3'}
            style={{ backgroundColor: "rgba(248, 248, 255, 1)",paddingTop: "50px", paddingBottom: "50px" }}
          >
            <TagTitle text={"Khảo sát"} />
            <div className={styles.title}>
              {fakeData.kiem_tra_khoa_hoc.title}
            </div>

            <div className={styles.group}>
              <ul className={styles.ul}>
                {fakeData.kiem_tra_khoa_hoc.data.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.teacher_container + " container"}>
        <div className={styles.title}>Giảng viên</div>
        <div className="row gx-3">
          <div className="col-xs-12 col-md-2">
            <img className={styles.avatar + ' img-fluid'} src={courseData.teacher.avatar} />
          </div>
          <div className="col-xs-12 col-md-10">
            <div className={styles.group}>
            <div className={styles.name}>{courseData.teacher?.name}</div>
            <div className={styles.major}>{courseData.teacher?.major}</div>
            <div className={styles.description}>
              {courseData.teacher?.description}
            </div>
            </div>
            
          </div>
        </div>
      </div>
      <RegisterSession />
    </div>
  );

  //   return <MinorCourseCard data={fakeData.khoa_hoc_chuan_bi.data[0]} />;
}
