import React from "react";
import styles from "./MinorCourseCard.module.css";
import { useNavigate } from "react-router-dom";
export const MinorCourseCard = ({ data, courseId }) => {
  const navigate = useNavigate();
  function onClickHandler() {
    navigate("/courses/detail", { state: { courseId } });
    console.log(courseId);
  }

  return (
    <div className={styles.container + " container-fluid"} onClick={onClickHandler} style={{ cursor: "pointer" }}>
      <div className="row">
        <div className="col-5">
          <img className="img-fluid" src={data.thumbnail_url} />
        </div>
        <div className={"col-7 text-start " + styles.wrapper}>
          <div className="d-grid gap-2">
            <div className={styles.title}>{data.name}</div>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <div
                className={
                  styles.text +
                  " d-flex justify-content-start align-items-center gap-1"
                }
              >
                <img
                  src={
                    require("assets/images/icon/svg/card/lesson.svg").default
                  }
                />{" "}
                Bài học
              </div>
              <div
                className={
                  styles.text +
                  " d-flex justify-content-start align-items-center gap-1"
                }
              >
                <img
                  src={
                    require("assets/images/icon/svg/light/clock-outline.svg")
                      .default
                  }
                />{" "}
                {data.duration} phút
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center gap-2">
              <img
                width={42}
                height={42}
                style={{ borderRadius: "50%" }}
                src={data.course_teacher.avatar}
              />
              <div className={styles.teacher_name}>
                {data.course_teacher.name}
              </div>
            </div>
            <div>
              <img
                style={{ cursor: "pointer" }}
                src={
                  require("assets/images/icon/svg/card/arrow-right.svg").default
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
