import styles from "./Teacher.module.css"; // Import your CSS styles

function Teacher({ data }) {
  function socialClickHandler(platform) {
    switch (platform) {
      case "twitter":
        window.open(data.twitter_url)
        break
      case "facebook":
        window.open(data.facebook_url)
        break
      case "instagram":
        window.open(data.instagram_url)
        break
      case "linkedin":
        window.open(data.linkedin_url)
        break
      default:
        break;
    }
  }

  return (
    <div className={styles.root_container}>
      <div className={styles.title}>Giảng viên</div>
      <div className={styles.divide} />

      <div className="container-fluid p-0">
        <div className="row align-items-center">
          <div className="col-xs-6 col-md-4">
            <img className={styles.teacher_avatar} src={data.avatar} />
          </div>
          <div className="col-xs-6 col-md-8">
            <div>
              <p className={styles.title}>{data.name}</p>
              <p>{data.major}</p>
              <div className="d-flex align-items-center gap-4">
                <div>
                  <img
                    src={
                      require("assets/images/icon/svg/card/star-solid.svg")
                        .default
                    }
                  />{" "}
                  <span>{data.teacher_rate_count} Đánh giá</span>
                </div>
                <div>
                  <img
                    src={
                      require("assets/images/icon/svg/card/lesson.svg").default
                    }
                  />{" "}
                  <span>{data.course} Khóa học</span>
                </div>
                <div>
                  <img
                    src={
                      require("assets/images/icon/svg/card/profile-user.svg")
                        .default
                    }
                  />{" "}
                  <span>{data.student_count} Học sinh</span>
                </div>
              </div>
              <p>{data.description}</p>
              <div className="d-flex gap-3">
                <img
                  onClick={() => socialClickHandler("twitter")}
                  className={`${styles.icon} ${!data.twitter_url && 'disabled-item'}`}
                  width={20}
                  height={20}
                  src={
                    require("assets/images/icon/svg/social/twitter.svg").default
                  }
                />

                <img
                  onClick={() => socialClickHandler("facebook")}
                  className={`${styles.icon} ${!data.facebook_url && 'disabled-item'}`}
                  width={20}
                  height={20}
                  src={
                    require("assets/images/icon/svg/social/facebook.svg")
                      .default
                  }
                />
                <img
                  onClick={() => socialClickHandler("instagram")}
                  className={`${styles.icon} ${!data.instagram_url && 'disabled-item'}`}
                  width={20}
                  height={20}
                  src={
                    require("assets/images/icon/svg/social/instagram.svg")
                      .default
                  }
                />
                <img
                  onClick={() => socialClickHandler("linkedin")}
                  className={`${styles.icon} ${!data.linkedin_url && 'disabled-item'}`}
                  width={20}
                  height={20}
                  src={
                    require("assets/images/icon/svg/social/linkedin.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teacher;
