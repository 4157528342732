import styles from "./SortBy.module.css";
import { useMainContext } from "contexts/MainContext";
function SortBy() {
  const { updateFilterOptions, removeFilterOptions } = useMainContext();
  function onChangeHandler(type) {
    if (type === 'null') {
      removeFilterOptions('order')
      return
    }
    const payload = {
      order: type
    }
    updateFilterOptions(payload)
  }
  return (
    <div className={styles.container}>
      <select className={styles.select} onChange={(evt) => onChangeHandler(evt.target.value)}>
        <option value={"null"}>Mặc định</option>
        <option value={"ASC"}>Giá: Thấp - Cao</option>
        <option value={"DESC"}>Giá: Cao - Thấp</option>
      </select>
      <div className={styles.label}>Sắp xếp theo</div>
    </div>
  );
}

export default SortBy;
