import React, { useState } from "react";
import styles from "./CourseDetailNavigate.module.css"; // Import your CSS styles

import { useMainContext } from "contexts/MainContext";
import {useNavigate} from 'react-router-dom'
const CourseDetailNavigate = () => {
  const navigate = useNavigate()
  const { courceLayout } = useMainContext();
  const [activeNav, setActiveNav] = useState('tong-quan')
  function clickNavigateHandler(value){
    setActiveNav(value)
    // navigate('/courses/detail#'+value)
  }
  const navList = [
    {name: 'Tổng quan', value: 'tong-quan'},
    {name: 'Nội dung khoá học', value: 'noi-dung-khoa-hoc'},
    {name: 'Yêu cầu', value: 'yeu-cau'},
    {name: 'Giảng viên', value: 'giang-vien'},
    {name: 'Đánh giá', value: 'danh-gia'},
  ]
  return (
    <div className={styles.container}>
      {
        navList.map((nav, index) => {
          return (
            <button onClick={() => clickNavigateHandler(nav.value)} className={activeNav === nav.value ? styles.btn_active : styles.btn }><a className={styles.a_custom} href={`#${nav.value}`}>{nav.name}</a></button>
          )
        })
      }
    </div>
  );
};

export default CourseDetailNavigate;
