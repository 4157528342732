// Header.js
import React, { useState, useEffect } from "react";
import styles from "./Teachers.module.css";
import "./Teachers.css";
import Pagination from "components/Pagination/Pagination";
import ToolBarTeachers from "components/ToolBarTeachers/ToolBarTeachers";
import TeacherCard from "components/TeacherCard/TeacherCard";
import BreadcrumbComponent from "components/Breadcrumb/Breadcrumb";
import { useMainContext } from "contexts/MainContext";
import apiService from "services/api.ts";
const ApiService = new apiService();
const Teachers = () => {
  const {filterOptionsTeacher} = useMainContext()
  const breadcrumbItems = [
    { text: "Trang chủ", href: "/" },
    { text: "Giảng viên", href: "/teachers" },
  ];
  // const [currentPage, setCurrentPage] = useState(1);
  // function pageChangeHandler(pageNumber) {
  //   setCurrentPage(pageNumber);
  // }

  const [allLecturer, setAllLecturer] = useState([]);
  async function getAllLecturer() {
    try {
      const res = await ApiService.executeGetRequest("education/lecturer", filterOptionsTeacher);
      if (res.status === 200) {
        console.log(res.data.data);
        setAllLecturer(res.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAllLecturer();
  }, [filterOptionsTeacher]);

  const teacherRender = allLecturer.map((item, index) => {
    return (
      <div key={index} className="col-xs-12 col-md-4 mt-3">
        <TeacherCard data={item} />
      </div>
    );
  });

  return (
    <div className="mb-5">
      {/* Session 1 */}
      <div className="container-fluid p-0">
        <div className={styles.session1_container}>
          <div className="container d-grid gap-5">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className={styles.session1_subcontainer}>
                  {/* <div className={styles.breadcrumb_custom}>
                    <div className={styles.breadcrumb_item}>Trang chủ</div>
                    <img
                      src={
                        require("assets/images/icon/svg/dark/arrow-breadcumb-right.svg")
                          .default
                      }
                    />
                    <div className={styles.breadcrumb_item_active}>
                      Tất cả chương trình học
                    </div>
                  </div> */}

                  <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />

                  <div className={styles.session1_title}>Đội ngũ giáo viên</div>

                  <div className={styles.session1_text}>
                    Lorem ipsum dolor sit amet consectetur. Volutpat auctor
                    sodales Volutpat auctor sodales
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div>
                <ToolBarTeachers />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Session 2 */}
      <div className={styles.session2_container}>
        <div className="container session2">
          <div className="row">{teacherRender}</div>
        </div>
      </div>
      {/* Session 3 */}
      {/* <div className={styles.session3_container}>
        <div className="container d-grid align-items-center">
          <Pagination
            currentPage={currentPage}
            pageSize={2}
            totalItems={100}
            onPageChanged={pageChangeHandler}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Teachers;
