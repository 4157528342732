import styles from "./SearchInput.module.css";
import "./SearchInput.css";
import debounce from "lodash/debounce";
import { useCallback } from "react";
import { useMainContext } from "contexts/MainContext";
function SearchInput({ placeholder, type }) {
  const { updateFilterOptions, updateFilterOptionsTeacher } = useMainContext();
  // Delay search by 600ms
  const sendQuery = (query) => {
    const payload = {
      search: query,
    };

    if (type === "course") {
      updateFilterOptions(payload);
    } else if (type === "teacher") {
      updateFilterOptionsTeacher(payload);
    }
  };
  const delayedSearch = useCallback(
    debounce((q) => sendQuery(q), 600),
    []
  );
  function changeSearchHandler(query) {
    delayedSearch(query);
  }
  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        placeholder={placeholder}
        onChange={(evt) => changeSearchHandler(evt.target.value)}
      />
      <img
        src={require(`assets/images/icon/svg/dark/circum_search.svg`).default}
      />
    </div>
  );
}

export default SearchInput;
