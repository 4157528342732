import RangeSlider from "../RangeSlider/RangeSlider";
import SelectCustom from "../SelectCustom/SelectCustom";
import styles from "./FilterTool.module.css";
import { useMainContext } from "contexts/MainContext";
import debounce from "lodash/debounce";
import { useCallback, useState, useEffect } from "react";
import apiService from "services/api.ts";
const ApiService = new apiService();
function FilterTool() {
  const [categoryOptions, setCategoryOptions] = useState([])
  const {
    filterOptions,
    updateFilterOptions,
    setFilterOptions,
    updateCourseLayout,
    removeFilterOptions,
  } = useMainContext();
  // Delay search by 600ms
  const sendQuery = (query) => {
    updateFilterOptions(query);
  };
  const delayedSearch = useCallback(
    debounce((q) => sendQuery(q), 600),
    []
  );
  function changePriceFilter(range) {
    delayedSearch(range);
  }

  async function getCategories() {
    try {
      const res = await ApiService.executeGetRequest(
        "education/course_categories"
      );
      if (res.status === 200) {
        setCategoryOptions(res.data.data)
        console.log(categoryOptions);
        // console.log(res);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getCategories();
  }, []);


  function selectCategoryHandler(option){
    if (option !== 'null') {
      const payload = {
        course_category_id: option
      }
      updateFilterOptions(payload);
    }
    else{
      removeFilterOptions('course_category_id')
    }
  }

  return (
    <>
      <div className={styles.hr} />
      <div className={styles.container + ""}>
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <SelectCustom title={"Lọc theo"} />
          </div>
          <div className="col-xs-12 col-md-3">
            <SelectCustom title={"Ưu đãi"} />
          </div>
          <div className="col-xs-12 col-md-3">
            <SelectCustom onChange={selectCategoryHandler} options={categoryOptions} title={"Danh mục"} />
          </div>
          <div className="col-xs-12 col-md-3">
            <RangeSlider
              min={0}
              max={500}
              step={1}
              onChange={changePriceFilter}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default FilterTool;
