import { useEffect, useState } from "react";
import styles from "./RegisterCourseForm.module.css";
import { useForm } from 'react-hook-form'
import apiService from "services/api.ts";
import { useMainContext } from "contexts/MainContext";
const ApiService = new apiService();
export default function RegisterCourseForm() {
  const { register, handleSubmit, reset } = useForm()
  const [isSubmitting, setSubmitting] = useState(false);
  const {programList} = useMainContext();
  // async function getProgramDetail() {
  //   try {
  //     const { data } = await ApiService.executeGetRequest("education/program");
  //     setProgramList(data.data);
  //     console.log(data.data);
  //   } catch (error) {}
  // }

  useEffect(() => {
    console.log(programList);
  }, []);

  async function onSubmitHandler(formData) {
    // event.preventDefault();

    const { name, phone, email, category } = formData;
    const payload = {
      name: name,
      phone: phone,
      mail: email,
      program_id: category,
    };

    setSubmitting(true);

    try {
      const { data } = await ApiService.executePostRequest(
        "education/program/register",
        payload
      );

      if (data.code === 200) {
        alert('Đăng ký thành công.')

        reset()
      }
    } catch (error) {
      alert('Đăng ký thất bại, vui lòng thử lại sau.')
    }

    setSubmitting(false);

    // console.log(payload);
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>Đăng ký khoá học</div>
      <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
        <label class={styles.label + " primary-color"}>Thông tin liên hệ</label>
        <input
          className={styles.input}
          type="text"
          name="name"
          {...register('name')}
          placeholder="Nhập họ và tên"
          required
        />
        <input
          className={styles.input}
          type="tel"
          name="phone"
          {...register('phone')}
          placeholder="Nhập số điện thoại"
        />
        <input
          className={styles.input}
          type="email"
          name="email"
          {...register('email')}
          placeholder="Email"
          required
        />

        <label for="category" class={styles.label + " primary-color"}>
          Chương trình học
        </label>
        <select
          className={styles.select}
          aria-label="Category course"
          name="category"
          {...register('category')}
        >
          <option selected hidden className={styles.option}>
            Chọn khoá học
          </option>
          {programList.map((item) => {
            return (
              <option className={styles.option} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </select>
        <button className={styles.button + " button-gradient " + (isSubmitting && ' disabled-item')}>
          Gửi đăng ký
        </button>
      </form>
    </div>
  );
}
