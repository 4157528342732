import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./components/NotFound/NotFound";
import MainLayout from "./layouts/MainLayout";
import Home from "./views/Home/Home";
import Courses from "views/Courses/Courses";
import Teachers from "views/Teachers/Teachers";
import { MainProvider } from "contexts/MainContext";
import CourseDetail from "components/CourseDetail/CourseDetail";
import CategoryCourse from "components/CategoryCourse/CategoryCourse";
import SupportLearner from "views/SupportLearner/SupportLearner";
import { Register } from "views/Register/Register";
import { useMainContext } from "contexts/MainContext";
import apiService from "services/api.ts";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const GA_TRACKING_ID = "G-QPYS8FNG8N";




const ApiService = new apiService()
ReactGA.initialize(GA_TRACKING_ID);

ReactGA.send({
  hitType: "FinHub Edu View",
  page: window.location.pathname,
});
function Root(){
  const {setProgramList} = useMainContext()
  useEffect(() => {
    async function getProgramList() {
      try {
        const { data } = await ApiService.executeGetRequest("education/program");
        setProgramList(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getProgramList()
  }, [])
  
  
  return (
    <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="courses">
              <Route path="" element={<Courses />} />
              <Route path="detail" element={<CourseDetail />} />
            </Route>
            <Route path="teachers" element={<Teachers />} />
            <Route path="category/:programId" element={<CategoryCourse />} />
            <Route path="support-learner" element={<SupportLearner />} />
            <Route path="register" element={<Register />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
  )

}
function App() {
  
  return (
    <MainProvider>
      {/* <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="courses">
              <Route path="" element={<Courses />} />
              <Route path="detail" element={<CourseDetail />} />
            </Route>
            <Route path="teachers" element={<Teachers />} />
            <Route path="category/:programId" element={<CategoryCourse />} />
            <Route path="support-learner" element={<SupportLearner />} />
            <Route path="register" element={<Register />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router> */}
      <Root />
    </MainProvider>
  );
}

export default App;
