import React, { useState } from "react";
import styles from "./SideBarCourseDetail.module.css"; // Import your CSS styles
import StarRating from "components/StarRating/StarRating";
import { useMainContext } from "contexts/MainContext";
import {useNavigate} from 'react-router-dom'
const SideBarCourseDetail = ({data}) => {

  const navigate = useNavigate()
  const { courceLayout } = useMainContext();
  const [collapseStatus, setCollapseStatus] = useState(true)
  function collapseHandler(){
    setCollapseStatus(!collapseStatus)
  }
  return (
    <div className={styles.root_container}>
      <div className={styles.root_wraper}>
        <div>
          <video
            controls
            className={styles.video}
            src={require("assets/videos/movie.mp4")}
          />
        </div>
        <div>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <div className="d-flex gap-2">
              <div className={styles.sale_price}>${data.sale_price}</div>
              <div className={styles.price}>${data.price}</div>
            </div>
            <div>
              <div
                className="d-flex align-items-center gap-1 d-none"
                style={{
                  color: "rgba(233, 80, 101, 1)",
                  cursor: "pointer",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                <img
                  width={20}
                  className="img-fluid"
                  src={
                    require("assets/images/icon/svg/light/clock.svg").default
                  }
                />
                <span>3 ngày còn lại</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-grid gap-2">
          <button className="button-gradient" onClick={()=> {navigate('/register')}}>Đăng ký khoá học</button>
          {/* <button className="button-outline">Mua ngay bây giờ</button> */}
        </div>

        <div className={styles.side_bar_course_info + ' ' + (collapseStatus ? styles.course_info_collapse : styles.course_info_expand)}>
          <div className="d-grid gap-3 mt-3">
            <div>
              <div className="d-flex justify-content-between">
                <span>Tổng thời gian</span>
                <span>{data.lesson.duration}</span>
              </div>
              <div className={styles.side_bar_divide} />
            </div>

            <div>
              <div
                className="d-flex justify-content-between "
                style={{ fontSize: "14px" }}
              >
                <span>Bài học</span>
                <span>{data.lesson.data.length}</span>
              </div>
              <div className={styles.side_bar_divide} />
            </div>

            <div className="d-none">
              <div className="d-flex justify-content-between">
                <span>Cấp độ</span>
                <span>Nền tảng</span>
              </div>
              <div className={styles.side_bar_divide} />
            </div>

            <div>
              <div className="d-flex justify-content-between">
                <span>Ngôn ngữ</span>
                <span>{data.language}</span>
              </div>
              <div className={styles.side_bar_divide} />
            </div>

            <div className="d-none">
              <div className="d-flex justify-content-between">
                <span>Bài tập</span>
                <span>05</span>
              </div>
              <div className={styles.side_bar_divide} />
            </div>

            <div >
              <div className="d-flex justify-content-between">
                <span>Chứng chỉ</span>
                <span>{data.certificate ? 'Có': 'Không'}</span>
              </div>
              <div className={styles.side_bar_divide} />
            </div>

            <div className="d-none">
              <div className="d-flex justify-content-between">
                <span>Tỉ lệ hoàn thành</span>
                <span>95%</span>
              </div>
              <div className={styles.side_bar_divide} />
            </div>
          </div>

          
        </div>
        <div onClick={collapseHandler} className="d-flex justify-content-between" style={{cursor: 'pointer'}}>
            <span className="primary-color">{collapseStatus ? 'Xem thêm' : 'Thu gọn'}</span>{" "}
            {
              collapseStatus ? (<img
                src={require(`assets/images/icon/svg/light/arrow-down.svg`).default}
              />) : (<img
                src={require(`assets/images/icon/svg/light/arrow-up.svg`).default}
              />)
            }
          </div>

        <div className="text-center d-grid gap-2">
          <div style={{ color: "rgba(130, 130, 130, 1)" }}>
            Để biết thêm thông tin
          </div>
          <button className="button-gradient px-0">
            <img
              src={
                require("assets/images/icon/svg/solar_phone-bold.svg").default
              }
            />{" "}
            Liên hệ chúng tôi: +84 7108 1868
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBarCourseDetail;
