import React from "react";
import styles from "./CourseCard.module.css"; // Import your CSS styles
import StarRating from "components/StarRating/StarRating";
import { useMainContext } from "contexts/MainContext";
import { useNavigate } from "react-router-dom";
const CourseCard = ({ data, display }) => {
  const navigate = useNavigate();
  const { courceLayout } = useMainContext();

  function courseDetailHandler(){
    navigate("/courses/detail", {
      state: {
        courseId: data.id,
      },
    });
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  if (!data) {
    return <></>;
  }
  const gridLayoutRender = () => {
    return (
      <div className={styles.card_container} onClick={courseDetailHandler}>
        <div>
          <img
            className="img-fluid"
            src={require("assets/images/pictures/jpg/card-1.jpg")}
          />
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <div className="d-flex justify-content-start">
            <StarRating rating={data.course_rates?.avg} />
            <span>{`(${data.course_rates.count} đánh giá)`}</span>
          </div>
          <div>
            <img
              className="img-fluid"
              src={require("assets/images/icon/svg/card/add-medal.svg").default}
            />
          </div>
        </div>

        <div className={styles.card_title}>{data.name}</div>
        <div className="d-flex justify-content-start gap-3">
          <div>
            <img
              className="img-fluid"
              src={require("assets/images/icon/svg/card/lesson.svg").default}
            />{" "}
            Bài học
          </div>
          <div>
            <img
              className="img-fluid"
              src={
                require("assets/images/icon/svg/card/profile-user.svg").default
              }
            />{" "}
            <span>{`(${data.student_count} học sinh)`}</span>
          </div>
        </div>

        <div>{data.short_description}</div>
        <div>
          <div
            className="d-flex align-items-center gap-2"
            style={{ cursor: "pointer" }}
          >
            <img
              className={styles.teacher_avatar}
              src={`${data.course_teacher.avatar}`}
            />
            <span style={{ fontWeight: 500 }}>{data.course_teacher.name}</span>
          </div>
        </div>

        <div className="d-flex justify-content-between w-100">
          <div className="d-flex gap-2">
            {data.sale_price ? (
              <>
                <div className={styles.sale_price}>{`${data.sale_price}`}</div>
                <div className={styles.price}>{`${data.price} $`}</div>
              </>
            ) : (
              <div className={styles.sale_price}>{`${data.price} $`}</div>
            )}
          </div>
          <div>
            <div style={{ color: "#21233D", cursor: "pointer" }}>
              Xem thêm{" "}
              <img
                className="img-fluid"
                src={
                  require("assets/images/icon/svg/card/arrow-right.svg").default
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const listLayoutRender = () => {
    return (
      <div className={styles.card_container}  onClick={courseDetailHandler}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <div>
                <img
                  className="img-fluid"
                  src={require("assets/images/pictures/jpg/card-1.jpg")}
                />
              </div>
            </div>
            <div className="col-xs-12 col-md-8">
              <div className={styles.card_list}>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "100%" }}
                >
                  <div className="d-flex justify-content-start">
                    <StarRating rating={data.course_rates?.avg} />
                    <span>{`(${data.course_rates.count} đánh giá)`}</span>
                  </div>
                  <div>
                    <img
                      className="img-fluid"
                      src={
                        require("assets/images/icon/svg/card/add-medal.svg")
                          .default
                      }
                    />
                  </div>
                </div>

                <div className={styles.card_title}>{data.name}</div>
                <div className="d-flex justify-content-start gap-3">
                  <div>
                    <img
                      className="img-fluid"
                      src={
                        require("assets/images/icon/svg/card/lesson.svg")
                          .default
                      }
                    />{" "}
                    Bài học
                  </div>
                  <div>
                    <img
                      className="img-fluid"
                      src={
                        require("assets/images/icon/svg/card/profile-user.svg")
                          .default
                      }
                    />{" "}
                    <span>{`(${data.student_count} học sinh)`}</span>
                  </div>
                </div>

                <div>{data.short_description}</div>
                <div>
                  <div
                    className="d-flex align-items-center gap-2"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className={styles.teacher_avatar}
                      src={`${data.course_teacher.avatar}`}
                    />
                    <span style={{ fontWeight: 500 }}>
                      {data.course_teacher.name}
                    </span>
                  </div>
                </div>

                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex gap-2">
                    {data.sale_price ? (
                      <>
                        <div
                          className={styles.sale_price}
                        >{`${data.sale_price}`}</div>
                        <div className={styles.price}>{`${data.price} $`}</div>
                      </>
                    ) : (
                      <div className={styles.sale_price}>{`${data.price} $`}</div>
                    )}
                  </div>
                  <div>
                    <div style={{ color: "#21233D", cursor: "pointer" }}>
                      Xem thêm{" "}
                      <img
                        className="img-fluid"
                        src={
                          require("assets/images/icon/svg/card/arrow-right.svg")
                            .default
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (display) {
    return display === "grid" ? gridLayoutRender() : listLayoutRender();
  }
  return courceLayout === "grid" ? gridLayoutRender() : listLayoutRender();
};

export default CourseCard;
