import { useRef, useEffect, useState } from "react";
import styles from "./CourseContent.module.css"; // Import your CSS styles

function CourseContent({data}) {
  const listSkill = [1, 2, 3, 4, 5, 6, 7];
  console.log(data);
  const fakeData = [
    {
      icon: "video.svg",
      text: "Thông tin cần biết",
      time: "30 phút",
      link: "",
    },
    {
      icon: "video.svg",
      text: "Thông tin cần biết",
      time: "30 phút",
      link: "",
    },
    {
      icon: "video.svg",
      text: "Thông tin cần biết",
      time: "30 phút",
      link: "",
    },
  ];
  const firstItemRef = useRef(null);
  const secondItemRef = useRef(null);
  const [firstItem, setFirstItem] = useState(false);
  const [secondItem, setSecondItem] = useState(false);
  useEffect(() => {
    const firstElement = firstItemRef.current;
    const secondElement = secondItemRef.current;

    // Define the observer callback function
    const handleAttributeChange = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        console.log(mutation);
        if (mutation.type === "attributes") {
          const attributeName = mutation.attributeName;
          const attributeValue1 = firstElement.getAttribute(attributeName);
          const attributeValue2 = secondElement.getAttribute(attributeName);
          setFirstItem(attributeValue1 === "true" ? true : false);
          setSecondItem(attributeValue2 === "true" ? true : false);
          console.log(
            `Attribute1 '${attributeName}' changed to '${attributeValue1}'  --- changed to '${attributeValue2}'`
          );
        }
      });
    };

    // Create an observer instance
    const observer = new MutationObserver(handleAttributeChange);

    // Start observing the 'data-custom-attribute' attribute
    observer.observe(firstElement, {
      attributes: true,
      attributeFilter: ["aria-expanded"],
    });
    observer.observe(secondElement, {
      attributes: true,
      attributeFilter: ["aria-expanded"],
    });

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div className={styles.root_container}>
      <div className={styles.title}>Nội dung khoá học</div>
      <div className={styles.divide} />

      <div className="container-fluid p-0 d-grid gap-4">
        <div className="row">
          <div
            ref={firstItemRef}
            className={
              styles.collapse_header + " " + (firstItem ? "primary-color" : "")
            }
            data-bs-toggle="collapse"
            data-bs-target="#collapseIFirst"
            aria-expanded="false"
            aria-controls="collapseIFirst"
          >
            <div className="d-flex align-items-center gap-5">
              <div className={styles.title_toggle}>Tổng thời gian khóa học</div>
              <div style={{ backgroundColor: "rgba(249, 249, 251, 1)" }}>
                <div className={styles.sub_title_toggle}>
                  <span className={firstItem ? "primary-color" : ""}>
                    {data.duration}
                  </span>
                </div>
              </div>
            </div>

            <div>
              {firstItem ? (
                <img
                  src={
                    require("assets/images/icon/svg/light/minus.svg").default
                  }
                />
              ) : (
                <img
                  src={require("assets/images/icon/svg/light/plus.svg").default}
                />
              )}
            </div>
          </div>
          <div className="collapse " id="collapseIFirst">
            <div className="d-grid gap-3 mt-4">
              {data.data.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={require('assets/images/icon/svg/light/video.svg').default}
                      />
                      <div>{item.description}</div>
                    </div>

                    <div className="d-flex gap-3">
                      <div>{item.duration} phút</div>
                      <div className="d-flex gap-2">
                        <img
                          src={
                            require(`assets/images/icon/svg/light/eye.svg`)
                              .default
                          }
                        />
                        <span className="primary-color"><a href={item.video_url} target="_blank">Xem trước</a></span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="row d-none">
          <div
            ref={secondItemRef}
            className={
              styles.collapse_header + " " + (secondItem ? "primary-color" : "")
            }
            data-bs-toggle="collapse"
            data-bs-target="#collapseSecond"
            aria-expanded="false"
            aria-controls="collapseSecond"
          >
            <div className="d-flex align-items-center gap-5">
              <div className={styles.title_toggle}>
                Tìm hiểu về Tài chính cơ bản
              </div>
              <div style={{ backgroundColor: "rgba(249, 249, 251, 1)" }}>
                <div className={styles.sub_title_toggle}>
                  <span className={secondItem ? "primary-color" : ""}>
                    2 giờ 20 phút
                  </span>
                </div>
              </div>
            </div>

            <div>
              {secondItem ? (
                <img
                  src={
                    require("assets/images/icon/svg/light/minus.svg").default
                  }
                />
              ) : (
                <img
                  src={require("assets/images/icon/svg/light/plus.svg").default}
                />
              )}
            </div>
          </div>
          <div className="collapse " id="collapseSecond">
            <div className="d-grid gap-3 mt-4">
              {fakeData.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={require(`assets/images/icon/svg/light/${item.icon}`)}
                      />
                      <div>{item.text}</div>
                    </div>

                    <div className="d-flex gap-3">
                      <div>{item.time}</div>
                      <div className="d-flex gap-2">
                        <img
                          src={
                            require(`assets/images/icon/svg/light/eye.svg`)
                              .default
                          }
                        />
                        <span className="primary-color">Xem trước</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseContent;
