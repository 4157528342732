import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaSlack,
  FaLinkedin,
} from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useMainContext } from "contexts/MainContext";
import MobileAppStore from "constants/mobile-store";
import Social from "constants/social";
const Footer = () => {
  const { programList } = useMainContext();
  return (
    <footer className="text-light footer-container">
      <Container>
        <Row className="gx-3">
          <Col
            xs={12}
            md={3}
            className="footer-introduce d-grid justify-content-start"
          >
            <Row>
              <img
                width={180}
                height={58}
                className="img-fluid"
                src={require("assets/images/logo/logo.svg").default}
                alt="Logo finhub edu"
              />
            </Row>
            <Row>
              <div>
                We’re always in search for talented and motivated people.
              </div>
            </Row>
            <Row>
              <div className="d-flex justify-content-between align-items-center">
                <img
                  src={require("assets/images/icon/png/qr.jpg")}
                  width={92}
                  height={92}
                  alt="QR Code"
                />
                <div className="d-grid gap-3">
                  <a href={MobileAppStore.ANDROID}>
                    <img
                      src={
                        require("assets/images/icon/svg/google-play.svg")
                          .default
                      }
                      width={130}
                      alt="Google Play"
                    />
                  </a>
                  <a href={MobileAppStore.ANDROID}>
                    <img
                      src={
                        require("assets/images/icon/svg/app-store.svg").default
                      }
                      width={130}
                      alt="App Store"
                    />
                  </a>
                </div>
              </div>
            </Row>
          </Col>

          <Col xs={12} md={3} className="footer-info-child ">
            <p className="footer-title">Đường dẫn</p>
            <ul className="list-unstyled footer-ul">
              <li>
                <Link to={"https://finhub.com.vn/about"} target="_blank">
                  Về FinHub
                </Link>
              </li>
              <li>
                <Link to={"https://finhub.com.vn/product"} target="_blank">
                  Sản phẩm
                </Link>
              </li>
              <li>
                <Link
                  to={"https://finhub.com.vn/system-finhub"}
                  target="_blank"
                >
                  Hệ thống FinHub
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={3} className="footer-info-child">
            <p className="footer-title">Chương trình học</p>
            <ul className="list-unstyled footer-ul">
              {programList.map((item) => (
                <li key={item.id}>
                  <Link to={`/category/${item.id}`}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={12} md={3} className="footer-info-child">
            <p className="footer-title">Liên hệ</p>

            <ul className="list-unstyled footer-ul">
              <li>
                <img
                  width={30}
                  height={30}
                  src={
                    require("assets/images/icon/svg/light/email.svg").default
                  }
                  alt="Email Icon"
                />{" "}
                nhan.nguyen@finhub.edu.vn
              </li>

              <li>
                <img
                  width={30}
                  height={30}
                  src={
                    require("assets/images/icon/svg/light/location.svg").default
                  }
                  alt="Phone Icon"
                />{" "}
                Trung tâm Đổi mới sáng tạo Quốc gia, tòa nhà NIC, ngõ 7 Tôn Thất
                Thuyết, phường Dịch Vọng Hậu, quận Cầu Giấy, Hà Nội
              </li>
            </ul>
            <div className="d-flex gap-4 footer-social">
              <a href={Social.FACEBOOK} target="_blank">
                <FaFacebook />
              </a>

              <a href={Social.TWITTER} target="_blank">
                <FaTwitter />
              </a>
              <a href={Social.INSTAGRAM} target="_blank">
                <FaInstagram />
              </a>
              <a href={Social.LINKEDIN} target="_blank">
                <FaLinkedin />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
