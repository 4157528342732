import React, { useEffect, useState } from "react";
import {useLocation} from 'react-router-dom'
import styles from "./CourseDetail.module.css"; // Import your CSS styles
import StarRating from "components/StarRating/StarRating";
import { useMainContext } from "contexts/MainContext";
import SideBarCourseDetail from "components/SideBarCourseDetail/SideBarCourseDetail";
import CourseDetailNavigate from "components/CourseDetailNavigate/CourseDetailNavigate";
import SkillsReceive from "components/ui/CourseDetail/SkillsReceive/SkillsReceive";
import CourseContent from "components/ui/CourseDetail/CourseContent/CourseContent";
import Requirements from "components/ui/CourseDetail/Requirements/Requirements";

import Teacher from "components/ui/CourseDetail/Teacher/Teacher";
import Rating from "components/ui/CourseDetail/Rating/Rating";
import InterestedCourseSuggest from "components/InterestedCourseSuggest/InterestedCourseSuggest";

import Utils from "utils";
import apiService from "services/api.ts";
import BreadcrumbComponent from "components/Breadcrumb/Breadcrumb";
import Loading from "components/ui/Loading/Loading";
const ApiService = new apiService();

const CourseDetail = () => {
  const location = useLocation();
  let courseId = location.state?.courseId;
  const [isLoading, setIsLoading] = useState(true);
  const [isRelatedCoursesShow, setRelatedCoursesShow] = useState(false);
  const { courceLayout } = useMainContext();
  const [detailData, setDetailData] = useState({})
  const breadcrumbItems = [
    { text: "Trang chủ", href: "/" },
    { text: "Tất cả chương trình học", href: "/courses" },
    { text: detailData.name, href: "/courses/detail" },
    
  ];
  
  
  useEffect(() => {
    getDetailCourse(courseId)
  }, [courseId])
  async function getDetailCourse(courseId){
    const payload = {
      courseId
    }
    const res = await ApiService.executeGetPathRequest('education/course', payload )
    if (res.status === 200) {
      setDetailData(res.data.data)
    }
    setIsLoading(false)
  }
  

  function relatedCoursesEnterViewportHandler(){
    console.log('enter');
    setRelatedCoursesShow(true)
  }

  function relatedCoursesLeaveViewportHandler(){
    console.log('leave');
    setRelatedCoursesShow(false)
  }

  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      <div style={{ position: "relative"}}>
        {/* Session 1 */}
        <div className="container-fluid p-0">
          <div className={styles.session1_container}>
            <div className="container d-grid gap-4">
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className={styles.session1_subcontainer}>
                  <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />

                    <div className={styles.session1_title}>
                    {detailData.name}
                    </div>

                    <div className={styles.session1_text}>
                    {detailData.short_description}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-start  gap-4">
                  <div className={styles.session1_best_seller_container}>
                    <img
                      src={
                        require("assets/images/icon/svg/light/premium-quality.svg")
                          .default
                      }
                    />
                    <span>Bán chạy nhất</span>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-center gap-2"
                    style={{ color: "white" }}
                  >
                    ({detailData.course_rates?.avg})
                    <StarRating rating={detailData.course_rates?.avg} />
                  </div>

                  <div
                    className="d-flex justify-content-start align-items-center gap-2"
                    style={{ color: "white" }}
                  >
                    <img
                      width={20}
                      height={20}
                      src={
                        require("assets/images/icon/svg/dark/member.svg")
                          .default
                      }
                    />
                    <span>{detailData.student_count} học sinh</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div>
                  <div
                    className="d-flex align-items-center gap-2"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className="img-fluid"
                      src={require("assets/images/pictures/png/teacher-avatar.png")}
                    />
                    <span style={{ fontWeight: 500, color: "white" }}>
                    {detailData.teacher?.name}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="d-flex justify-content-start  gap-4">
                  <div
                    className="d-flex justify-content-start align-items-center gap-2"
                    style={{ color: "white" }}
                  >
                    <img
                      width={20}
                      height={20}
                      src={
                        require("assets/images/icon/svg/dark/radix-icons_calendar.svg")
                          .default
                      }
                    />
                    <span>Lần cuối cập nhật {new Utils().formatDateToDDMMYYYY(detailData.updated_at)}</span>
                  </div>

                  <div
                    className="d-flex justify-content-start align-items-center gap-2"
                    style={{ color: "white" }}
                  >
                    <img
                      width={20}
                      height={20}
                      src={
                        require("assets/images/icon/svg/dark/light_language.svg")
                          .default
                      }
                    />
                    <span>{detailData.language}</span>
                  </div>

                  <div
                    className="d-flex justify-content-start align-items-center gap-2"
                    style={{ color: "white" }}
                  >
                    <img
                      width={20}
                      height={20}
                      src={
                        require("assets/images/icon/svg/dark/ph_certificate-light.svg")
                          .default
                      }
                    />
                    {
                      detailData.certificate ? <span>Cấp chứng chỉ</span> : <></>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                    
        <div className={(isRelatedCoursesShow ? styles.side_bar_absolute : styles.side_bar_fixed) +' '+ styles.side_bar}>
          <SideBarCourseDetail data={detailData} />
        </div>
        

        <div className="container d-grid gap-4 mt-3">
          <div className="row">
            <div className="col-xs-12 col-md-8 d-grid gap-3 px-0">
              <div>
                <img
                  className="img-fluid w-100"
                  src={detailData.thumbnail_url}
                />
              </div>

              <div>
                <CourseDetailNavigate />
              </div>

              {/* Bạn học được gì? */}
              <div id="tong-quan">
                <SkillsReceive data={detailData.what_you_can_learn} />
              </div>

              <div id="noi-dung-khoa-hoc">
                <CourseContent data={detailData.lesson} />
              </div>
              <div id="yeu-cau">
                <Requirements data={detailData.requirement} />
              </div>

              <div id="giang-vien">
                <Teacher data={detailData.teacher}/>
              </div>

              <div id="danh-gia">
                <Rating data={detailData.course_rates} />
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="divide" />
          </div>
        </div>
      </div>
      <div className="container-fluid p-0 my-4">
      <InterestedCourseSuggest onEnterViewport={relatedCoursesEnterViewportHandler} onLeaveViewport={relatedCoursesLeaveViewportHandler} courseId={courseId} />
      </div>
    </>
  );
};

export default CourseDetail;
