import CourseCard from "components/CourseCard/CourseCard";
import styles from "./InterestedCourseSuggest.module.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Loading from "components/ui/Loading/Loading";
import apiService from "services/api.ts";
const ApiService = new apiService();

function InterestedCourseSuggest({
  courseId,
  onEnterViewport,
  onLeaveViewport,
}) {
  const targetRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [relatedCourses, setRelatedCourses] = useState([]);
  useEffect(() => {
    getCoursesRelated(courseId);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onEnterViewport && onEnterViewport();
        } else {
          onLeaveViewport && onLeaveViewport();
        }
      },
      {
        threshold: 0.01, // 50% of the target visible
      }
    );

    console.log(targetRef.current);

    observer.observe(targetRef.current);

    // return () => {
    //   observer.unobserve(targetRef.current);
    // };
  }, [onEnterViewport, onLeaveViewport]);

  async function getCoursesRelated(courseId) {
    const payload = {
      courseId,
    };
    const res = await ApiService.executeGetPathRequest(
      "education/course_related",
      payload
    );
    if (res.status === 200) {
      setRelatedCourses(res.data.data);
      setIsLoading(false);
    }
    console.log(res.data.data);
  }

  // if(isLoading){
  //   return <Loading />
  // }
  return (
    <div ref={targetRef} className={styles.container + " container"}>
      <div className="row">
        <div className={styles.title}>Có thể bạn quan tâm</div>
      </div>
      <div className="row gy-3">
        {isLoading ? (
          <Loading />
        ) : (
          relatedCourses.map((item, index) => {
            return (
              <div className="col-xs-12 col-md-4">
                <CourseCard data={item} layout="grid" />
              </div>
            );
          })
        )}
      </div>

      {/* <div className="row  mt-4">
        <div className="d-flex justify-content-center">
          <Link to={"/courses"}>
            <button className={styles.button_outline_sky}>
              Xem tất cả{" "}
              <img
                src={
                  require("assets/images/icon/svg/arrow-right-sky.svg").default
                }
              />
            </button>
          </Link>
        </div>
      </div> */}
    </div>
  );
}

export default InterestedCourseSuggest;
