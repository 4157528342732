import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
// import { Auth } from 'aws-amplify';

export default class ApiService {
    private http: AxiosInstance;

    constructor() {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        // const baseUrl = "https://35j85drxff.execute-api.ap-southeast-1.amazonaws.com/dev";
        // console.log('baseUrl ', baseUrl);
        const axiosConfig: AxiosRequestConfig = {
            baseURL: baseUrl,
            headers: {},
            withCredentials: false,
            timeout: 60000,
        };

        this.http = axios.create(axiosConfig);

        this.initializeRequestInterceptor();
    }

    private initializeRequestInterceptor() {
        this.http.interceptors.request.use(
            async (config: any) => {
                try {
                    // const session = await Auth.currentSession();
                    // config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
                    return config;
                } catch (error) {
                    console.error("Error in request interceptor", error);
                    return Promise.reject(error);
                }
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    public async executeGetRequest(url: string, params = {}, headers = null): Promise<AxiosResponse | string | null> {
        try {
            const response = await this.http.get(url, { params, timeout: 600000 });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }
    public async executeGetPathRequest(url: string, params = {}, headers = null): Promise<AxiosResponse | string | null> {
        try {
            // Create an array of path values from the remaining parameters
            const pathValues = Object.values(params).join('/');
            
            const fullUrl = `${url}/${pathValues}`
            params = {}
            const response = await this.http.get(fullUrl, { params, timeout: 600000 });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }

    public async executePostRequest(url: string, payload: object, headers = null): Promise<AxiosResponse | string | null> {
        try {
            const response = await this.http.post(url, payload, { timeout: 600000 });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }
    public async executePutRequest(url: string, payload: object, headers = null): Promise<AxiosResponse | string | null> {
        try {
            const response = await this.http.put(url, payload, { timeout: 600000 });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }
    public async executeDeleteRequest(url: string, payload: object, headers = null): Promise<AxiosResponse | string | null> {
        try {
            const response = await this.http.delete(url, { data: payload });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }
}

// export default ApiService;
