import { useState } from "react";
import styles from "./ToggleLayout.module.css";
import { useMainContext } from "contexts/MainContext";
function ToggleLayout() {
  const [layout, setLayout] = useState("grid");
  const {updateCourseLayout} = useMainContext()
  function changeLayoutHandler(layout){
    console.log(layout);
    setLayout(layout)
    updateCourseLayout(layout)
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.item + ' ' + ( layout === 'grid' ? styles.active : '' )} onClick={() => changeLayoutHandler("grid")}>
          {layout === "grid" ? (
            <img
              src={
                require(`assets/images/icon/svg/light/ion_grid-outline.svg`)
                  .default
              }
            />
          ) : (
            <img
              src={
                require(`assets/images/icon/svg/dark/ion_grid-outline.svg`)
                  .default
              }
            />
          )}
         <span className={layout === 'grid' ? 'primary-color' : ''}>Lưới</span>
        </div>
        <div className={styles.item + ' ' + ( layout === 'list' ? styles.active : '' )}  onClick={() => changeLayoutHandler("list")}>
        {layout === "list" ? (
            <img
              src={
                require(`assets/images/icon/svg/light/tabler_list.svg`)
                  .default
              }
            />
          ) : (
            <img
              src={
                require(`assets/images/icon/svg/dark/tabler_list.svg`)
                  .default
              }
            />
          )}
          <span className={layout === 'list' ? 'primary-color' : ''}>Danh sách</span>
        </div>
      </div>
    </>
  );
}

export default ToggleLayout;
