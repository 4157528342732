// Header.js
import React, { useState } from "react";
import styles from "./Home.module.css";
import CourseCard from "components/CourseCard/CourseCard";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApiService from "services/api.ts";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PopularCourses from "components/PopularCourses/PopularCourses";
import RegisterSession from "components/RegisterSession/RegisterSession";
import SocialComponent from "components/SocialComponent/SocialComponent";
import { TagTitle } from "components/ui/TagTitle/TagTitle";

const Home = () => {
  const navigate = useNavigate();
  const [popularCourses, setPopularCourses] = useState([]);
  async function getPopularCourses() {
    const res = await new ApiService().executeGetRequest(
      "/education/course_popular"
    );
    if (res.status === 200) {
      setPopularCourses(res.data.data);
    } else {
      console.error(res);
    }
  }

  useEffect(() => {
    getPopularCourses();
  }, []);

  function courseDetailHandler(id) {
    navigate("/courses/detail", {
      state: {
        courseId: id,
      },
    });
  }

  return (
    <div className={styles.home_container}>
      {/* Session 1 */}
      <div className="container mt-3">
        <div className="row">
          <div className="col-xs-12 col-md-6 d-flex align-items-center">
            <div className={styles.banner_right_container}>
              <div className={styles.banner_right_title}>
                Vượt qua rào cản tài chính với sự hỗ trợ của FinHub Edu
              </div>
              <div>
                Hoàn thiện kiến thức tài chính cho người dùng từ cơ bản đến nâng
                cao.
              </div>
              <div className="d-flex gap-3">
                <button className={styles.button_primary} onClick={() => navigate('/register')}>Đăng ký</button>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className={styles.banner_left_container}>
              <img
                className="img-fluid"
                src={require("assets/images/pictures/png/home-iphone-banner.png")}
              />
              <div className={styles.banner_left_card_container}>
                <SocialComponent />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Session 2 */}
      <div className="container">
        <div className="row">
          <div className={styles.session2_container}>
            <div className={styles.session2_header}>
              <div className="d-flex justify-content-center">
                <TagTitle
                  text={"Khoá học phổ biến"}
                  style={{
                    background: "rgba(24, 112, 245, 0.12)",
                    color: "#1870f5",
                  }}
                />
              </div>

              <div className={styles.session2_header_item2}>
                Được lựa chọn nhiều nhất
              </div>

              <div></div>
            </div>
          </div>
        </div>
        <div className="row">
          <PopularCourses />
        </div>
      </div>

      {/* Session 3 */}
      <div className="container">
        <div className="row mt-4">
          <div className={styles.session2_container}>
            <div className={styles.session3_header}>
              <div className="d-flex justify-content-center">
                <TagTitle text={"Tốt Nhất Cho Nhu Cầu"} />
                {/* <div className={[styles.session3_header_item1]}>
                  Tốt Nhất Cho Nhu Cầu
                </div> */}
              </div>

              <div className={styles.session3_header_item2}>
                Tại sao chọn Fin<span className="primary-color">Edu!</span>
              </div>

              <div></div>
            </div>
          </div>
        </div>

        <div className="row mt-4 gy-3">
          <div className="col-xs-12 col-md-3">
            <div
              className={
                styles.session3_card_container + " " + styles.session3_card1
              }
            >
              <div className={styles.session3_card_title}>
                Linh hoạt và tiết kiệm thời gian
              </div>
              <img
                className="img-fluid"
                src={require("assets/images/icon/png/save-time.png")}
              />

              <div className={styles.session3_card_text}>
                Khóa học online mang lại sự linh hoạt cho học viên để học tập
                mọi nơi, từ nhà đến nơi làm việc hay trên đường đi. Bạn có thể
                tự quản lý thời gian học tập sao cho phù hợp với lịch trình cá
                nhân và không cần di chuyển đến địa điểm cụ thể.Khóa học online
                mang lại sự linh hoạt cho học viên để học tập mọi nơi, từ nhà
                đến nơi làm việc hay trên đường đi. Bạn có thể tự quản lý thời
                gian học tập sao cho phù hợp với lịch trình cá nhân và không cần
                di chuyển đến địa điểm cụ thể.
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div
              className={
                styles.session3_card_container + " " + styles.session3_card2
              }
            >
              <div className={styles.session3_card_title}>
                Nguồn kiến thức đa dạng
              </div>
              <img
                className="img-fluid"
                src={require("assets/images/icon/png/loi-ich-icon-2.png")}
              />

              <div className={styles.session3_card_text}>
                Khóa học online được thiết kế và giảng dạy bởi những chuyên gia
                có kinh nghiệm trong lĩnh vực tài chính. Bạn có cơ hội học từ
                những người có kiến thức chuyên sâu và thực tế.Khóa học online
                được thiết kế và giảng dạy bởi những chuyên gia có kinh nghiệm
                trong lĩnh vực tài chính. Bạn có cơ hội học từ những người có
                kiến thức chuyên sâu và thực tế.
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-3">
            <div
              className={
                styles.session3_card_container + " " + styles.session3_card3
              }
            >
              <div className={styles.session3_card_title}>Được cập nhật liên tục</div>
              <img
                className="img-fluid"
                src={require("assets/images/icon/png/loi-ich-icon-3.png")}
              />

              <div className={styles.session3_card_text}>
              Ngoài ra do tính chất trực tuyến, nội dung của khóa học có thể được cập nhật nhanh chóng để phản ánh những xu hướng và thay đổi mới nhất trong lĩnh vực tài chính.
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-3">
            <div
              className={
                styles.session3_card_container + " " + styles.session3_card4
              }
            >
              <div className={styles.session3_card_title}>Tương tác với giảng viên</div>
              <img
                className="img-fluid"
                src={require("assets/images/icon/png/loi-ich-icon-4.png")}
              />

              <div className={styles.session3_card_text}>
              Một số khóa học cung cấp cơ hội tương tác trực tiếp với giảng viên qua các buổi thảo luận trực tuyến, hỏi-đáp, hoặc các phương tiện tương tác khác.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Session 4 */}
      <RegisterSession />
    </div>
  );
};

export default Home;
