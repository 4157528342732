import MobileAppStore from "constants/mobile-store";
import styles from "./SocialComponent.module.css";
export default function SocialComponent() {
  return (
    <div className={styles.banner_left_card_container}>
      <img
        className="img-fluid"
        width={92}
        height={92}
        src={require("assets/images/icon/png/qr.jpg")}
      />
      <div className="d-grid" style={{ gap: "15px" }}>
        <a href={MobileAppStore.ANDROID}>
          <img
            className="img-fluid"
            width={130}
            src={require("assets/images/icon/svg/google-play.svg").default}
          />
        </a>
        <a href={MobileAppStore.APPLE}>
          <img
            className="img-fluid"
            width={130}
            src={require("assets/images/icon/svg/app-store.svg").default}
          />
        </a>
      </div>
    </div>
  );
}
