import './ProgressRating.css'

function ProgressRating({ percent }) {
  return (
    <div class="progress progress_custom">
      <div
        class="progress-bar progress_bar_custom "
        role="progressbar"
        aria-label="Basic example"
        style={{width: `${percent}%`}}
        aria-valuenow={`${percent}`}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
}

export default ProgressRating;
