// Header.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Courses.module.css";
import "./Courses.css";
import CourseCard from "components/CourseCard/CourseCard";
import Breadcrumb from "react-bootstrap/Breadcrumb";


import { Link } from "react-router-dom";
import ToolBar from "components/ToolBar/ToolBar";
import Pagination from "components/Pagination/Pagination";
import { useMainContext } from "contexts/MainContext";
import apiService from "services/api.ts";
import BreadcrumbComponent from "components/Breadcrumb/Breadcrumb";
const ApiService = new apiService();
const Courses = () => {
  const navigate = useNavigate();
  const { courceLayout, filterOptions } = useMainContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [allCourses, setAllCourses] = useState([]);
  const breadcrumbItems = [
    { text: "Trang chủ", href: "/" },
    { text: "Tất cả chương trình học", href: "/courses" },
    
  ];
  // async function getAllCourses() {
  //   try {
  //     const res = await ApiService.executeGetRequest("education/course");
  //     if (res.status === 200) {
  //       setAllCourses(res.data.data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // useEffect(() => {
  //   getAllCourses();
  // }, []);

  async function filterHandler(filterOptions) {
    if (filterOptions) {
      try {
        const res = await ApiService.executeGetRequest(
          "education/course",
          filterOptions
        );
        if (res.status === 200) {
          setAllCourses(res.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    console.log(filterOptions);
    filterHandler(filterOptions);
  }, [filterOptions]);

  // function pageChangeHandler(pageNumber) {
  //   setCurrentPage(pageNumber);
  // }

  function courseDetailHandler(id) {
    navigate("/courses/detail", {
      state: {
        courseId: id,
      },
    });
  }

  const courseRender = allCourses.map((item, index) => {
    return (
      <>
        {courceLayout === "grid" ? (
          <div
            key={index}
            className="col-xs-12 col-md-4 mt-3"
            onClick={() => courseDetailHandler(item.id)}
          >
            <CourseCard data={item} layout={courceLayout} />
          </div>
        ) : (
          <div key={index} className="col-xs-12 col-md-12 mt-3">
            <CourseCard data={item} layout={courceLayout} />
          </div>
        )}
      </>
    );
  });

  return (
    <div>
      {/* Session 1 */}
      <div className="container-fluid p-0">
        <div className={styles.session1_container}>
          <div className="container d-grid gap-5">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className={styles.session1_subcontainer}>
                  <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />

                  <div className={styles.session1_title}>
                    Tất cả chương trình học
                  </div>

                  <div className={styles.session1_text}>
                    Lorem ipsum dolor sit amet consectetur. Volutpat auctor
                    sodales Volutpat auctor sodales
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div>
                <ToolBar />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Session 2 */}
      <div className={styles.session2_container}>
        <div className="container session2">
          <div className="row">{courseRender}</div>
        </div>
      </div>
      {/* Session 3 */}
      {/* <div className={styles.session3_container}>
        <div className="container d-grid align-items-center">
          <Pagination
            currentPage={currentPage}
            pageSize={2}
            totalItems={100}
            onPageChanged={pageChangeHandler}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Courses;
