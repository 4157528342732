// // Header.js
import React, { useEffect, useState } from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LogoImage from "assets/images/logo/logo.svg";
import { Link, useLocation } from "react-router-dom";
import styles from "./Header.module.css";
const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [links, setLinks] = useState({
    home: {
      path: "https://finhub.com.vn/",
      active: false,
    },
    about: {
      path: "https://finhub.com.vn/about",
      active: false,
      subMenu: [
        {
          name: "Slogan",
          path: "https://finhub.com.vn/about/slogan",
        },
        {
          name: "Giới thiệu",
          path: "https://finhub.com.vn/about/gioi-thieu",
        },
        {
          name: "Đội ngũ quản lý",
          path: "https://finhub.com.vn/about/doi-ngu-quan-ly",
        },
        {
          name: "Giá trị cốt lõi",
          path: "https://finhub.com.vn/about/gia-tri-cot-loi",
        },
        {
          name: "Sản phẩm",
          path: "https://finhub.com.vn/about/san-pham",
        },
        {
          name: "Đối tác & Đơn vị hỗ trợ",
          path: "https://finhub.com.vn/about/doi-tac-va-don-vi-ho-tro",
        },
        {
          name: "Cơ hội nghề nghiệp",
          path: "https://finhub.com.vn/about/co-hoi-nghe-nghiep",
        },
      ],
    },
    // product: {
    //   path: "https://finhub.com.vn/product",
    //   active: false,
    //   subMenu: [
    //     {
    //       title: "Kiến thức tài chính",
    //       child: [
    //         {
    //           name: "Thuật ngữ",
    //           path: "https://finhub.com.vn/kien-thuc/thuat-ngu",
    //         },
    //         {
    //           name: "Blog",
    //           path: "https://finhub.com.vn/product/blog",
    //         },
    //       ],
    //     },
    //     {
    //       title: "Công cụ hỗ trợ tài chính",
    //       child: [
    //         {
    //           name: "Chứng chỉ quỹ mở",
    //           path: "https://finhub.com.vn/product/chung-chi-quy-mo",
    //         },
    //         {
    //           name: "Robo Advidor",
    //           path: "https://finhub.com.vn/product/robo-advisor",
    //         },
    //       ],
    //     },
    //   ],
    // },

    product: {
      path: "https://finhub.com.vn/product",
      active: false,
      subMenu: [
        {
          title: "Kiến thức",
          link: 'https://finhub.com.vn/kien-thuc',
          child: [
            {
              name: "Thuật ngữ",
              path: "https://finhub.com.vn/kien-thuc/thuat-ngu",
            },
          ],
        },
        {
          title: "Chứng chỉ quỹ mở",
          link: 'https://finhub.com.vn/product/chung-chi-quy-mo',
          child: [
        
          ],
        },
        {
          title: "Công cụ",
          link: 'https://finhub.com.vn/cong-cu',
          child: [
            {
              name: "Robo Advisor",
              path: "https://finhub.com.vn/cong-cu/robo-advisor",
            },
            {
              name: "Mô phỏng đầu tư",
              path: "https://finhub.com.vn/cong-cu/mo-phong-dau-tu-quy-mo",
            },
            
          ],
        },
      ],
    },
    system: {
      path: "https://finhub.com.vn/system-finhub",
      active: false,
    },
    support: {
      path: "https://finhub.com.vn/support",
      active: false,
    },
    blog: {
      path: "https://finhub.com.vn/blog",
      active: false,
    },
  });
  useEffect(() => {
    const newLinks = { ...links };
    for (let item of Object.keys(newLinks)) {
      // if (links[item].path === currentPath) {
      if (currentPath.includes(links[item].path)) {
        console.log(item);
        links[item].active = true;
      } else {
        links[item].active = false;
      }
    }
    setLinks(newLinks);
  }, [currentPath]);
  const eduSubHeader = [
    {
      name: "Tất cả chương trình học",
      link: "/courses",
    },
    {
      name: "Đội ngũ giảng viên",
      link: "/teachers",
    },
    {
      name: "Hỗ trợ học viên",
      link: "/support-learner",
    },
    {
      name: "Chương trình khuyến mãi",
      link: "/promotions",
    },
  ];
  const renderSubHeader = eduSubHeader.map((item, index) => {
    if (item.name === "Tất cả chương trình học") {
      const programList = [
        {
          id: 1,
          name: "Tài chính cơ bản",
        },
        {
          id: 2,
          name: "Tài chính cá nhân",
        },
        {
          id: 3,
          name: "Thu nhập thụ động",
        },
        {
          id: 4,
          name: "Quản trị rủi ro",
        },
      ];
      return (
        <NavDropdown
          title="Tất cả chương trình học"
          id="navbarScrollingDropdown"
          className={styles.item}
        >
          {programList.map((item) => {
            return (
              <NavDropdown.Item
                key={item.id}
                as={Link}
                to={`category/${item.id}`}
                style={{
                  color: "#21233D",
                  fontWeight: 500,
                  textDecoration: "none",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: '10px'
                }}
              >
                <img
                  src={
                    require("assets/images/icon/svg/carbon_course.svg").default
                  }
                />
                <span>{item.name}</span>
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      );
    }
    return (
      <Link className={styles.item} key={index} to={item.link}>
        {" "}
        {item.name}{" "}
      </Link>
    );
  });
  return (
    <header
      className=""
      style={{
        boxShadow:
          "0px 2px 4px 0px rgba(17, 17, 26, 0.05), 0px 4px 16px 0px rgba(17, 17, 26, 0.05)",
        flexShrink: 0,
      }}
    >
      <div className="container">
        <Navbar expand="lg" className="header_container">
          <Container fluid>
            <Navbar.Brand as={Link} to={"/"} className="mt-0">
              <img width={175} height={59} src={LogoImage} alt="finhub logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="mx-auto my-2 my-lg-0 header_content" navbarScroll>
                <NavDropdown
                  title="Về FinHub"
                  id="navbarScrollingDropdown"
                  className={
                    links.about.active ? "header-item-active mt-0" : "mt-0"
                  }
                >
                  {links.about.subMenu.map((item, index) => {
                    return (
                      <NavDropdown.Item
                        as={Link}
                        to={item.path}
                        style={{
                          color: "#21233D",
                          fontWeight: 500,
                          fontSize: '17px',
                          textDecoration: "none",
                        }}
                      >
                        {item.name}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>

                <NavDropdown
                  title="Sản phẩm"
                  id="navbarScrollingDropdown"
                  className={links.product.active ? "header-item-active" : ""}
                >
                  {links.product.subMenu.map((item, index) => {
                    return (
                      <>
                        <NavDropdown.Item
                          as={Link}
                          to={"https://finhub.com.vn/product"}
                        >
                          <div className="text-header">{item.title}</div>
                        </NavDropdown.Item>

                        <NavDropdown.Divider />

                        {item.child.map((childItem, childIndex) => {
                          return (
                            <NavDropdown.Item
                              key={childIndex}
                              as={Link}
                              to={childItem.path}
                              style={{
                                color: "#21233D",
                                fontWeight: 500,
                                textDecoration: "none",
                              }}
                            >
                              <div className="d-flex gap-2  align-items-center">
                                <img
                                  src={
                                    require("../../assets/images/icon/svg/arrow-left.svg")
                                      .default
                                  }
                                />
                                <span>{childItem.name}</span>
                              </div>
                            </NavDropdown.Item>
                          );
                        })}
                      </>
                    );
                  })}
                </NavDropdown>

                <Nav.Link
                  as={Link}
                  to={"https://finhub.com.vn/system-finhub"}
                  style={{
                    color: "#21233D",
                    fontWeight: 500,
                    textDecoration: "none",
                  }}
                  className={links.system.active ? "header-item-active" : ""}
                >
                  Hệ thống FinHub
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to={"https://finhub.com.vn/support"}
                  style={{
                    color: "#21233D",
                    fontWeight: 500,
                    textDecoration: "none",
                  }}
                  className={links.support.active ? "header-item-active" : ""}
                >
                  Hỗ trợ khách hàng
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to={"https://finhub.com.vn/blog"}
                  style={{
                    color: "#21233D",
                    fontWeight: 500,
                    textDecoration: "none",
                  }}
                  className={links.support.active ? "header-item-active" : ""}
                >
                  Blog
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div className={styles.submenu_container + " container"}>
        {renderSubHeader}
      </div>
    </header>
  );
};

export default Header;
