import StarRating from "components/StarRating/StarRating";
import styles from "./Rating.module.css"; // Import your CSS styles
import ProgressRating from "components/ui/ProgressRating/ProgressRating";
import { useEffect } from "react";

function Rating({ data }) {
  let listStar = [
    // { star_count: 5, percent: 95 },
    // { star_count: 4, percent: 82 },
    // { star_count: 3, percent: 6 },
    // { star_count: 2, percent: 3 },
    // { star_count: 1, percent: 1 },
  ];
  const renderListAuthen = () => {
    for (let item of Object.keys(data.course_rate_sumarize)) {
      let newObject = {};
      switch (item) {
        case "one_star":
          newObject = {
            star_count: 1,
            percent: (
              (data.course_rate_sumarize["one_star"] / data.count) *
              100
            ).toFixed(1),
          };
          listStar.push(newObject);
          break;
        case "two_star":
          newObject = {
            star_count: 2,
            percent: (
              (data.course_rate_sumarize["two_star"] / data.count) *
              100
            ).toFixed(1),
          };
          listStar.push(newObject);
          break;
        case "three_star":
          newObject = {
            star_count: 3,
            percent: (
              (data.course_rate_sumarize["three_star"] / data.count) *
              100
            ).toFixed(1),
          };
          listStar.push(newObject);
          break;
        case "four_star":
          newObject = {
            star_count: 4,
            percent: (
              (data.course_rate_sumarize["four_star"] / data.count) *
              100
            ).toFixed(1),
          };
          listStar.push(newObject);
          break;
        case "five_star":
          newObject = {
            star_count: 5,
            percent: (
              (data.course_rate_sumarize["five_star"] / data.count) *
              100
            ).toFixed(1),
          };
          listStar.push(newObject);
          break;
        default:
          break;
      }
    }

    listStar.reverse();
    return (
      <div className={styles.right_container}>
        {listStar.map((item, index) => {
          return (
            <div key={index} className={styles.right_item}>
              <StarRating rating={item.star_count} />
              <div style={{ width: "100%" }}>
                <ProgressRating percent={item.percent} />
              </div>
              <div className={styles.right_text}>{`${item.percent}%`} </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className={styles.root_container}>
      <div className={styles.title}>Đánh giá</div>
      <div className={styles.divide} />

      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-xs-6 col-md-4">
            <div className={styles.left_container}>
              <div className={styles.left_title}>{data.avg}</div>
              <StarRating rating={data.avg} />
              <div>Xếp hạng đánh giá</div>
            </div>
          </div>
          <div className="col-xs-6 col-md-8">{renderListAuthen()}</div>
        </div>
      </div>
    </div>
  );
}

export default Rating;
