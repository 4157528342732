import styles from "./SelectCustom.module.css";
function SelectCustom({ title, options, onChange }) {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{title}</div>
      <select className={styles.select} onChange={(event) => onChange(event.target.value)}>
        <option value={'null'}>Mặc định</option>
        {options ? (
          options.map((item, index) => {
            return (
              <>
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              </>
            );
          })
        ) : (
          <></>
        )}
      </select>
    </div>
  );
}

export default SelectCustom;
