// UserContext.jsx

import React, { useState, useContext, createContext, useEffect } from "react";
import ApiService from "services/api.ts";
const MainContext = createContext({
  courceLayout: "grid",
  programList: [],
  filterOptions: {
    category: null,
    price: null,
  },
  filterOptionsTeacher: {
    
  },
  updateFilterOptions: (options) => {},
  updateCourseLayout: (layout) => {},
  removeFilterOptions: (option) => {},
  updateFilterOptionsTeacher: (option) => {},
  setProgramList: (list) => {},
});

export const useMainContext = () => {
  const context = useContext(MainContext);
  if (context === undefined) {
    throw new Error("useMainContext must be within UserProvider");
  }

  return context;
};

export const MainProvider = ({ children }) => {
  const [courceLayout, setCourseLayout] = useState("grid");
  const [programList, setProgramList] = useState([]);
  const [allCourses, setAllCourse] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterOptionsTeacher, setFilterOptionsTeacher] = useState({});
  function updateFilterOptions(newOptions) {
    setFilterOptions((prevFilter) => ({...prevFilter, ...newOptions}));
  }
  function removeFilterOptions(option) {
    const updatedFilterOptions = { ...filterOptions };
    console.log(updatedFilterOptions);
    delete updatedFilterOptions[option]
    setFilterOptions(updatedFilterOptions);
  }

  function updateCourseLayout(layout) {
    setCourseLayout(layout);
  }

  function updateFilterOptionsTeacher(newOptions) {
    setFilterOptionsTeacher((prevFilter) => ({...prevFilter, ...newOptions}));
  }

  const value = {
    courceLayout,
    updateCourseLayout,
    filterOptions,
    updateFilterOptions,
    removeFilterOptions,
    filterOptionsTeacher,
    updateFilterOptionsTeacher,
    programList,setProgramList
  };
  return (
    <>
      <MainContext.Provider value={value}>{children}</MainContext.Provider>
    </>
  );
};
