import styles from "./Loading.module.css";

function Loading({style}){
    return (
        <div className={styles.loadingContainer} style={style}>
          <div className={styles.spinner}></div>
        </div>
      );
}

export default Loading