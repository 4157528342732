class Utils {
  constructor() {}

  shortenText(text, maxLength) {
    // Split the text into words
    const words = text.split(" ");

    // Check if the text length is within the maximum length
    if (words.length <= maxLength) {
      return text; // If it's within the limit, return the original text
    } else {
      // If it's longer, truncate the text and add "..."
      const shortenedText = words.slice(0, maxLength).join(" ") + "...";
      return shortenedText;
    }
  }
  formatDateToDDMMYYYY(dateString) {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
  
    // Create a Date object from the input date string
    const date = new Date(dateString);
  
    // Convert to Vietnam time zone (UTC+7)
    date.setTime(date.getTime() + 7 * 60 * 60 * 1000);
  
    // Format the date using the options
    return date.toLocaleDateString('vi-VN', options);
  }

  convertToTitleCase(str) {
    return str
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}

export default Utils
