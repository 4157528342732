import ToggleLayout from "components/ToggleLayout/ToggleLayout";
import FilterButton from "components/ui/FilterButton/FilterButton";
import FilterTool from "components/ui/FilterTool/FilterTool";
import SearchInput from "components/ui/SearchInput/SearchInput";
import SortBy from "components/ui/SortBy/SortBy";
import { useState } from "react";
function ToolBarTeachers() {
  const [showFilterTool, setShowFilterTool] = useState(true);

  function filterButtonHandler() {
    setShowFilterTool(!showFilterTool);
  }
  const FilterToolRender = () => {
    if (showFilterTool) {
      return <FilterTool />;
    }

    return <></>;
  };
  return (
    <>
      <div className="d-flex justify-content-end">
        {/* <div className="d-flex gap-3 align-items-center">
          <ToggleLayout />
          <span style={{ color: "white" }}>Hiển thị từ 1-9</span>
        </div> */}
        <div className="d-flex gap-3 align-items-center">
          <SearchInput placeholder={'Tìm kiếm giảng viên'} type={'teacher'} />
          <SortBy />

          {/* <FilterButton onClickFilter={filterButtonHandler} /> */}
        </div>
      </div>

      {/* <div className="mt-3">{FilterToolRender()}</div> */}
    </>
  );
}

export default ToolBarTeachers;
