import React from 'react';
import './NotFound.css'; // Import your CSS styles

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found-content">
        <h1 className='primary-color'>404</h1>
        <h2>Page Not Found</h2>
        <p>Sorry, the page you are looking for does not exist.</p>
        <a href="/">Go to Home</a>
      </div>
    </div>
  );
};

export default NotFound;
