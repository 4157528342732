import styles from "./FilterButton.module.css";
function FilterButton({onClickFilter}) {
  return (
    <div className={styles.container} onClick={onClickFilter}>
      <div className={styles.text}>Bộ lọc <img src={require("assets/images/icon/svg/light/flowbite_filter-outline.svg").default} /></div>
    </div>
  );
}

export default FilterButton;
