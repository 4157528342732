import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CourseCard from "components/CourseCard/CourseCard";
import styles from "./PopularCourses.module.css";
import ApiService from "services/api.ts";
import Loading from "components/ui/Loading/Loading";

function PopularCourses() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [popularCourses, setPopularCourses] = useState([]);
  async function getPopularCourses() {
    const res = await new ApiService().executeGetRequest(
      "/education/course_popular"
    );
    if (res.status === 200) {
      setPopularCourses(res.data.data);
    } else {
      console.error(res);
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getPopularCourses();
  }, []);

  function courseDetailHandler(id) {
    navigate("/courses/detail", {
      state: {
        courseId: id,
      },
    });
  }


  const renderPopularCourses = popularCourses.map((item, index) => {
    return (
      <div
        key={index}
        className="col-xs-12 col-md-4"
        onClick={() => courseDetailHandler(item.id)}
      >
        <CourseCard data={item} display={"grid"} />
      </div>
    );
  });

  if (isLoading) {
    return <Loading />
  }
  return <>{renderPopularCourses}</>;
}

export default PopularCourses;
