import React, { useState } from "react";
import { useCollapse } from "react-collapsed";
import styles from "./SupportLearner.module.css";
// Separate Collapsible Component

function CollapsibleSection({ id, title, content }) {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  return (
    <div className={styles.container}>
      <div
        className={styles.title + " " + (isExpanded && styles.active)}
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded),
        })}
      >
        <span>{title}</span>
        {isExpanded ? (
          <img src={require("assets/images/icon/svg/arrow-down.svg").default} />
        ) : (
          <img src={require("assets/images/icon/svg/arrow-up.svg").default} />
        )}
        {/* <img src={require("assets/images/icon/svg/arrow-down.svg").default} /> */}
      </div>
      {/* <button
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded),
        })}
      >
        {isExpanded ? "Collapse" : "Expand"} {title}
      </button> */}
      <section className={styles.content} {...getCollapseProps()}>
        {content}
      </section>
    </div>
  );
}

function SupportLearner() {
  // Assuming you have multiple sections
  const sections = [
    {
      id: "1",
      title: "Ai là giảng viên chính của khóa học?",
      content:
        "Lorem ipsum dolor sit amet consectetur. Risus massa posuere ante varius dictumst vitae id suscipit. Convallis egestas nulla est a aenean velit sit tortor auctor. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam.",
    },
    {
      id: "2",
      title: "Có phản hồi từ học viên trước không?",
      content:
        "Lorem ipsum dolor sit amet consectetur. Risus massa posuere ante varius dictumst vitae id suscipit. Convallis egestas nulla est a aenean velit sit tortor auctor. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam.",
    },
    {
      id: "3",
      title: "Làm thế nào để đăng ký khóa học?",
      content:
        "Lorem ipsum dolor sit amet consectetur. Risus massa posuere ante varius dictumst vitae id suscipit. Convallis egestas nulla est a aenean velit sit tortor auctor. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam.",
    },
    {
        id: "4",
        title: "Học phí bao gồm những gì và có những tùy chọn thanh toán nào?",
        content:
          "Lorem ipsum dolor sit amet consectetur. Risus massa posuere ante varius dictumst vitae id suscipit. Convallis egestas nulla est a aenean velit sit tortor auctor. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam.",
      },
      {
        id: "5",
        title: "Làm thế nào để truy cập vào các bài giảng và tài liệu?",
        content:
          "Lorem ipsum dolor sit amet consectetur. Risus massa posuere ante varius dictumst vitae id suscipit. Convallis egestas nulla est a aenean velit sit tortor auctor. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam.",
      },
      {
        id: "6",
        title: "Làm thế nào để liên lạc với giảng viên hoặc đội ngũ hỗ trợ?",
        content:
          "Lorem ipsum dolor sit amet consectetur. Risus massa posuere ante varius dictumst vitae id suscipit. Convallis egestas nulla est a aenean velit sit tortor auctor. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam.",
      },
      {
        id: "7",
        title: "Có diễn đàn hoặc cộng đồng nơi học viên có thể trao đổi thông tin không?",
        content:
          "Lorem ipsum dolor sit amet consectetur. Risus massa posuere ante varius dictumst vitae id suscipit. Convallis egestas nulla est a aenean velit sit tortor auctor. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam.",
      },
      {
        id: "8",
        title: "Nếu tôi cần hủy đăng ký, quy trình và điều kiện như thế nào?",
        content:
          "Lorem ipsum dolor sit amet consectetur. Risus massa posuere ante varius dictumst vitae id suscipit. Convallis egestas nulla est a aenean velit sit tortor auctor. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam.",
      },
      {
        id: "9",
        title: "Có chính sách hoàn phí hoặc hoàn lại học phí không?",
        content:
          "Lorem ipsum dolor sit amet consectetur. Risus massa posuere ante varius dictumst vitae id suscipit. Convallis egestas nulla est a aenean velit sit tortor auctor. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam. Vulputate ullamcorper id enim ipsum egestas eget tortor aliquam.",
      },
  ];

  return (
    <div className={styles.root_container + " container d-grid gap-3"}>
      <div className={styles.title + " primary-color text-center"}>
        Câu hỏi thường gặp
      </div>
      <div className="d-grid gap-3">
        {sections.map((section) => (
          <CollapsibleSection
            key={section.id}
            id={section.id}
            content={section.content}
            title={section.title}
          />
        ))}
      </div>
    </div>
  );
}

export default SupportLearner;
