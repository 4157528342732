import ToggleLayout from "components/ToggleLayout/ToggleLayout";
import FilterButton from "components/ui/FilterButton/FilterButton";
import FilterTool from "components/ui/FilterTool/FilterTool";
import SearchInput from "components/ui/SearchInput/SearchInput";
import SortBy from "components/ui/SortBy/SortBy";
import { useState } from "react";
function ToolBar() {
  const [showFilterTool, setShowFilterTool] = useState(false);

  function filterButtonHandler() {
    setShowFilterTool(!showFilterTool);
  }
  const FilterToolRender = () => {
    if (showFilterTool) {
      return <FilterTool />;
    }

    return <></>;
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-3 align-items-center">
          <ToggleLayout />
          {/* <span style={{ color: "white" }}>Hiển thị từ 1-9</span> */}
        </div>
        <div className="d-flex gap-3 align-items-center">
          <SearchInput placeholder={'Tìm kiếm khóa học'} type={'course'} />
          <SortBy />

          <FilterButton onClickFilter={filterButtonHandler} />
        </div>
      </div>

      <div className="mt-3">{FilterToolRender()}</div>
    </>
  );
}

export default ToolBar;
