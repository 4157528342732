import React from "react";
import { RegisterCourseForm } from "./RegisterCourseForm";
import styles from "./RegisterSession.module.css";
import Image from 'assets/images/pictures/png/home-iphone-banner.png'
import SocialComponent from "components/SocialComponent/SocialComponent";
function RegisterSession() {
  return (
    <div className={styles.container}>

    
    <div className="container" >
      <div className={styles.row + " row gx-5 align-items-center"}>
        <div className="col-xs-12 col-md-6" style={{position: 'relative'}}>
          <div className={styles.title}>
            Vượt qua rào cản tài chính với sự hỗ trợ của <span className="primary-color">FinHub Edu</span>
          </div>
          <img width={400} className="img-fluid" src={Image} />
          <div className={styles.left}>
            <SocialComponent />
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <RegisterCourseForm />
        </div>
      </div>
    </div>
    </div>
  );
}

export default RegisterSession;
