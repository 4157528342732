import React from "react";
import styles from "./TeacherCard.module.css"; // Import your CSS styles
import StarRating from "components/StarRating/StarRating";
const TeacherCard = ({data}) => {
  return (
    <div className={styles.card_container}>
      <div>
        <img
          className={"img-fluid " + styles.avatar}
          // height={415}
          // width={353}
          src={data.avatar}
        />
      </div>

      <div className={styles.card_title}>{data.name}</div>
      <div className={styles.role + ' primary-color'}>{data.major}</div>

      <div className={styles.location}><img width={28} height={28} src={require("assets/images/icon/svg/light/fluent_location.svg").default} />{data.location}</div>



      
    </div>
  );
};

export default TeacherCard;
