import React from "react";
import styles from "./StarRating.module.css"; // Import your CSS styles

import PropTypes from "prop-types";

const StarRating = ({ rating }) => {
  const maxRating = 5;
  const filledStars = Math.min(Math.max(Math.round(rating), 0), maxRating); // Ensure rating is between 0 and 5
  const emptyStars = maxRating - filledStars;

  const filledStar = (
    <span className="star" role="img" aria-label="Filled Star">
      <img
        src={require("assets/images/icon/svg/card/star-solid.svg").default}
      />
    </span>
  );

  const emptyStar = (
    <span className="star" role="img" aria-label="Empty Star">
      <img
        src={require("assets/images/icon/svg/card/star-outline.svg").default}
      />
    </span>
  );
  if (!rating) {
    return null
  }

  return (
    <div className={styles.star_rating}>
      {[...Array(filledStars)].map((_, index) => (
        <span key={index}>{filledStar}</span>
      ))}
      {[...Array(emptyStars)].map((_, index) => (
        <span key={index}>{emptyStar}</span>
      ))}
    </div>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default StarRating;
